import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import getComparator from "../../utils/sort"
import searchQuotations from "../../utils/search"
import { useQuotationContext } from "../quotation-provider"

const QuotationTableContext = createContext()
const MAX_SELECTABLE_ROWS = 15

function QuotationTableProvider({ children }) {
  const { t } = useTranslation("orderManagement")

  // TABLE DATA
  const [tableData, setTableData] = useState(undefined);

  // SELECTED QUOTATIONS
  const [selectedQuotations, setSelectedQuotations] = useState([])

  // QUOTED QUOTATIONS -> only use these in Validation/Create Label flow if unquoted quotations are selected + Bulk Create Label Action
  const selectedQuotationsWithQuotes = useMemo(() =>
      selectedQuotations.filter((quotation) => quotation.quote !== undefined),
    [selectedQuotations]
  );

  // snackbar
  const { setSnackBar } = useQuotationContext()

  // PAGINATION
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(15)
  const [orderDirection, setOrderDirection] = useState("asc")
  const [orderBy, setOrderBy] = useState("orderId")
  const [sortedAndPaginatedData, setSortedAndPaginatedData] = useState([])

  // SEARCH STATE
  const [searchText, setSearchText] = useState("")
  const [searchField, setSearchField] = useState("orderId")

  // TABLE DATA FUNCTIONS
  const updateTableQuotation = (quotationData, shouldKeepQuote=false) => {
    // Update the table data
    setTableData((prevTableData) =>
      prevTableData.map((row) =>
        row.rateRequestId === quotationData.rateRequestId
          ? {
            ...row,
            ...quotationData,
            ...(shouldKeepQuote ? {} : { quote: undefined })
          }
          : row
      )
    );

    // Update the selected quotations if the updated quotation exists in selectedQuotations
    setSelectedQuotations((prevSelectedQuotations) =>
      prevSelectedQuotations.map((selectedQuotation) =>
        selectedQuotation.rateRequestId === quotationData.rateRequestId
          ? {
            ...selectedQuotation,
            ...quotationData,
            ...(shouldKeepQuote ? {} : { quote: undefined })
          }
          : selectedQuotation
      )
    );
  }

  const updateLoadingStateBatch = (orders, loadingType, isLoading) => {
    // Create a lookup object for rateRequest IDs
    const rateRequestIdsObject = orders.reduce((acc, order) => {
      acc[order.rateRequestId] = true; // Use `true` as a placeholder value
      return acc;
    }, {});

    // Update the table data
    setTableData((prevTableData) =>
      prevTableData.map((row) => {
        if (rateRequestIdsObject[row.rateRequestId]) {
          return {
            ...row,
            loading: {
              ...row.loading,
              [loadingType]: isLoading,
            },
          };
        }
        return row;
      })
    );
  };

  const updateLoadingState = (quotationData, loadingType, isLoading) => {
    setTableData((prevTableData) =>
      prevTableData.map((row) => {
        if (row.rateRequestId === quotationData.rateRequestId) {
          const newLoadingState = {
            ...row.loading,
            [loadingType]: isLoading,
          };
          if (JSON.stringify(row.loading) === JSON.stringify(newLoadingState)) {
            return row; // No changes, return the same object to avoid unnecessary renders
          }
          return { ...row, loading: newLoadingState };
        }
        return row;
      })
    );
  };

  // TODO: update this to use the updateTableQuotation as a "middleware"

  const setQuote = (quoteResponse) => {
    // Extract rateRequestIds and corresponding quotes from the response
    const rateRequestIdsObject = Object.keys(quoteResponse).reduce((acc, rateRequestId) => {
      acc[rateRequestId] = true;
      return acc;
    }, {});


    // Update table data
    setTableData((prevTableData) =>
      prevTableData.map((row) =>
        rateRequestIdsObject[row.rateRequestId]
          ? { ...row, quote: quoteResponse[row.rateRequestId] }
          : row
      )
    );

    // Update selected quotations
    setSelectedQuotations((prevSelectedQuotations) =>
      prevSelectedQuotations.map((selectedQuotation) =>
        rateRequestIdsObject[selectedQuotation.rateRequestId]
          ? { ...selectedQuotation, quote: quoteResponse[selectedQuotation.rateRequestId] }
          : selectedQuotation
      )
    );
  };

  const deleteQuotation = ({ rateRequestId }) => {
    setSelectedQuotations((prevSelected) =>
      prevSelected.filter((quote) => quote.rateRequestId !== rateRequestId)
    );
    setTableData((prevTableData) =>
      prevTableData.filter((row) => row.rateRequestId !== rateRequestId)
    );
  }

  const updateErrorStateBatch = (orders, errorType, isError) => {
    // Create a lookup object for rateRequest IDs
    const rateRequestIdsObject = orders.reduce((acc, order) => {
      acc[order.rateRequestId] = true; // placeholder
      return acc;
    }, {});

    // Update the table data
    setTableData((prevTableData) =>
      prevTableData.map((row) => {
        if (rateRequestIdsObject[row.rateRequestId]) {
          return {
            ...row,
            error: {
              ...row.error,
              [errorType]: isError,
            },
          };
        }
        return row;
      })
    );
  };

  const updateErrorState = ({ quotation, errorType, isError }) => {
    setTableData((prevTableData) =>
      prevTableData.map((row) =>
        row.rateRequestId === quotation.rateRequestId
          ? { ...row, error: { ...row.error, [errorType]: isError } }
          : row
      )
    );
  }

  const addQuotation = (quotationData) => {
    setTableData((prevTableData) => {
      if (!prevTableData) return [quotationData];
      return [quotationData, ...prevTableData]
    })
  }


  // PAGINATION FUNCTIONS

  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && orderDirection === "asc"
    setOrderDirection(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const selectAllQuotations = () => {
    // Only select up to MAX_SELECTABLE_ROWS
    const quotationsToSelect = sortedAndPaginatedData.slice(0, MAX_SELECTABLE_ROWS)
    setSelectedQuotations(quotationsToSelect)

    // Set snackbar on next render (using setTimeout) to prevent React warning: `Cannot update a component (QuotationProvider) while rendering a different component (QuotationTableProvider)`
    if (sortedAndPaginatedData.length >= MAX_SELECTABLE_ROWS) {
      setTimeout(() => {
        setSnackBar({severity: "info", message: `${t("snackbar.maxSelectedReachPrefix.label")} ${MAX_SELECTABLE_ROWS}`})
      }, 0)
    }
  }

  const updatedSelectedQuotations = (quotation) => {
    let willReachLimit = false
    let willExceedLimit = false

    setSelectedQuotations((prevSelectedQuotations) => {
      const isAlreadySelected = prevSelectedQuotations.some(
        (selectedQuotation) => selectedQuotation.rateRequestId === quotation.rateRequestId
      );

      if (isAlreadySelected) {
        return prevSelectedQuotations.filter(
          (selectedQuotation) => selectedQuotation.rateRequestId !== quotation.rateRequestId
        );
      }

      // Check if adding would exceed the maximum
      if (prevSelectedQuotations.length >= MAX_SELECTABLE_ROWS) {
        willExceedLimit = true
        return prevSelectedQuotations
      }

      if (prevSelectedQuotations.length + 1 === MAX_SELECTABLE_ROWS) {
        willReachLimit = true
      }

      return [...prevSelectedQuotations, quotation];
    });

    // Set snackbar on next render (using setTimeout) to prevent React warning: `Cannot update a component (QuotationProvider) while rendering a different component (QuotationTableProvider)`
    setTimeout(() => {
      if (willExceedLimit) {
        setSnackBar({severity: "warning", message: `${t("snackbar.tooManySelectedPrefix.label")} ${MAX_SELECTABLE_ROWS}`})
      } else if (willReachLimit) {
        setSnackBar({severity: "info", message: `${t("snackbar.maxSelectedReachPrefix.label")} ${MAX_SELECTABLE_ROWS}`})
      }
    }, 0)
  }

  const clearSelectedQuotations = () => {
    setSelectedQuotations([])
  }

  useEffect(() => {
    // First filter using existing search function
    const filteredData = searchText
      ? searchQuotations(tableData, searchField, searchText)
      : tableData;

    // If no data, reset sorted and paginated data
    if (!filteredData || filteredData.length === 0) {
      setSortedAndPaginatedData([]);
      setPage(1);
      return;
    }

    // Then sort and paginate
    const processed = filteredData
      .slice()
      .sort(getComparator(orderDirection, orderBy))
      .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage);

    setSortedAndPaginatedData(processed);

    // Reset to first page when search changes
    if (searchText) {
      setPage(1);
    }
  }, [tableData, page, rowsPerPage, orderDirection, orderBy, searchText, searchField]);

  const getTotalFilteredCount = () => {
    if (!tableData) return 0;
    if (!searchText) return tableData.length;
    return searchQuotations(tableData, searchField, searchText).length;
  };

  const value = useMemo(() => ({
      page,
      setPage,
      rowsPerPage,
      setRowsPerPage,
      orderDirection,
      setOrderDirection,
      setOrderBy,
      orderBy,
      handleRequestSort,
      sortedAndPaginatedData,
      selectAllQuotations,
      selectedQuotations,
      setSelectedQuotations,
      updatedSelectedQuotations,
      clearSelectedQuotations,
      tableData,
      setTableData,
      updateTableQuotation,
      updateLoadingState,
      setQuote,
      deleteQuotation,
      updateErrorState,
      updateErrorStateBatch,
      addQuotation,
      searchText,
      setSearchText,
      searchField,
      setSearchField,
      selectedQuotationsWithQuotes,
      updateLoadingStateBatch,
      totalFilteredCount: getTotalFilteredCount(),
      maxSelectableRows: MAX_SELECTABLE_ROWS,
      isAtSelectionLimit: selectedQuotations.length >= MAX_SELECTABLE_ROWS,

  }), [
    page,
    rowsPerPage,
    orderDirection,
    orderBy,
    sortedAndPaginatedData,
    selectedQuotations,
    tableData,
    selectedQuotationsWithQuotes,
  ])


  return (
    <QuotationTableContext.Provider value={value}>
      {children}
    </QuotationTableContext.Provider>
  )
}

QuotationTableProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useQuotationTableContext = () => {
  return useContext(QuotationTableContext)
}

export default QuotationTableProvider
