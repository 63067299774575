import React, { useState } from "react"
import { useCreateQuotationMutation } from "../../slice"
import { useQuotationContext } from "../../state/quotation-provider"
import { useQuotationTableContext } from "../../state/quotations-table-provider"
import DateTimeService from "../../../../services/date-time"

function createQuotation() {
  const [createQuotationMutation] = useCreateQuotationMutation()
  const [isCreateQuotationLoading, setIsCreateQuotationLoading] = useState(false)
  const {
    setAlert,
  } = useQuotationContext();

  const { addQuotation } = useQuotationTableContext()


  // TODO: right now we need to include "source: csv" when we optimistically update so that the "source" isn't overwritten when the optimistic data is updated by src/components/order-management/utils/transformData.js
  // if "source" isn't "csv" then the "GET" request filters it out
  const newQuotation = async (orderId) =>{
    setIsCreateQuotationLoading(true)
    try {
      const payload = {
        orderId,
      }
      const response = await createQuotationMutation({ payload }).unwrap()
      if (response.error) {
        throw new Error("Unable to create quotation")
      }
      addQuotation({
        orderId,
        rateRequestId: response.rateRequestId,
        createdDate: DateTimeService.toISOString(Date.now()),
        shippingType: "cheapest",
        source: "csv",
      })
    } catch (err) {
      setAlert({
        severity: "error",
        message: "Error Creating quotation",
      })
    } finally {
      setIsCreateQuotationLoading(false)
    }
  }

  return { newQuotation, isCreateQuotationLoading }
}

export default createQuotation;
