import React, { useState } from "react"
import { Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import NewQuotationDialog from "../../../dialog/new-quotation-dialog"
import createQuotation from "../../../../hooks/create-quotation"
import SoeAddIcon from "../../../../../../soe-theme/src/icons/SoeAddIcon"
import { pxToRem } from "../../../../../../soe-theme/src/utils"

function NewQuotationButton() {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const { newQuotation } = createQuotation()
  const {t} = useTranslation("orderManagement")

  const handleOnClick = async (event) => {
    event.stopPropagation()
    setIsConfirmationModalOpen(true)
  }
  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false)
  }

  const handleCreateQuotation = async (orderId) => {
    await newQuotation(orderId)
  }

  return (
    <>
      <Tooltip title={t("buttons.createQuotation.tooltip")}>
        <SoeAddIcon
          fontSize="large"
          onClick={handleOnClick}
          sx={(theme)=>(
          {borderRadius: "400px",
            backgroundColor: `${theme.palette.inexPrimary.main}`,
            color: "white",
            padding: pxToRem(3),
            "&:hover": {
              cursor: "pointer",
              backgroundColor: `${theme.palette.inexSecondary.main}`,
            }
          })}
        />
      </Tooltip>
      <NewQuotationDialog
        open={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleCreateQuotation}
        title={t("buttons.createQuotation.title")}
        cancelText={t("buttons.createQuotation.cancel")}
        confirmText={t("buttons.createQuotation.confirm")}
        handleClose={handleCloseConfirmationModal}
        label={t("buttons.createQuotation.label")}
      />
    </>
  )
}

export default NewQuotationButton
